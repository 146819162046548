import styled from "@emotion/styled";

const StyledFooter = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding: 2rem;
  height: 10rem;
  line-height: 2.5rem;

  @media (min-width: 768px) {
    height: 8rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Copyright = styled.p`
  font-size: 1.6rem;
  font-family: "Play", sans-serif;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const ContactInfo = styled.p`
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Copyright>DockLine Solutions LLC</Copyright>
      <ContactInfo>info@docklinesolutions.com</ContactInfo>
    </StyledFooter>
  );
};

export default Footer;
