const scrollToHash = (hash) => {
  const targetElement = document.getElementById(hash);
  if (targetElement) {
    const headerOffset = 80; // Height of your header in pixels
    const elementPosition = targetElement.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

export default scrollToHash;
