import React from "react";
import styled from "@emotion/styled";

const StyledServices = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #2f708b;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 6rem 2rem;
  width: 100%;

  @media (min-width: 768px) {
    padding: 6rem 4rem;
  }

  @media (min-width: 1024px) {
    padding: 8rem 6rem;
  }
`;

const ServicesHeading = styled.h2`
  font-size: clamp(3rem, 5vw, 5rem);
  font-family: "Play", sans-serif;
  width: 100%;
  max-width: 2000px;
  text-align: left;
  margin-bottom: 4rem;
`;

const ServicesContent = styled.div`
  max-width: 2000px;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 4rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
`;

const ServiceTitle = styled.h3`
  font-size: clamp(2.5rem, 3vw, 3rem);
  font-family: "Play", sans-serif;
  margin-bottom: 1.5rem;
`;

const ServiceDescription = styled.p`
  font-size: clamp(1.8rem, 2.5vw, 2rem);
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  line-height: 1.6;
`;

const SERVICES_DATA = [
  {
    title: "Warehousing",
    description:
      "We provide secure, climate-controlled storage with flexible terms and inventory management to optimize your stock.",
  },
  {
    title: "Logistics",
    description:
      "We offer efficient shipping, real-time tracking, and tailored last-mile delivery to ensure fast, cost-effective distribution.",
  },
  {
    title: "Packaging",
    description:
      "We produce high-quality shipping materials, including custom crates and eco-friendly packaging, with tailored designs for unique needs.",
  },
  {
    title: "Supply Chain",
    description:
      "We provide end-to-end supply chain management, logistics consulting, and strategic support to optimize operations and drive scalable growth.",
  },
];

const Services = () => {
  return (
    <StyledServices id="services">
      <ServicesHeading>Services</ServicesHeading>
      <ServicesContent>
        {SERVICES_DATA.map((service, index) => (
          <ServiceCard key={index}>
            <ServiceTitle>{service.title}</ServiceTitle>
            <ServiceDescription>{service.description}</ServiceDescription>
          </ServiceCard>
        ))}
      </ServicesContent>
    </StyledServices>
  );
};

export default Services;
