import React from "react";
import styled from "@emotion/styled";
import Button from "./Button";

const HeroWrapper = styled.section`
  position: relative;
  color: #fff;
  width: 100%;
  height: 100vh;
  max-height: 1200px;
  overflow: hidden;
`;

const BackgroundLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #b2b2b2;
  background-image: url("https://storage.googleapis.com/tiger-dc-web/images/dockbackground/xl.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(2px);
  z-index: 1;
`;

const FilterLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 3;
  max-width: 2000px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  height: 100%;
  @media (min-width: 768px) {
    justify-content: unset;
    align-items: unset;
    gap: 2rem;
    padding: 30vh 3rem;
    height: 100%;
  }

  @media (min-width: 1024px) {
    padding: 30vh 6rem;
  }
`;

const Title = styled.h1`
  font-family: "Play", sans-serif;
  color: #fff;
  font-weight: 700;
  font-size: clamp(1.8rem, 9vw, 6.6rem);
  line-height: clamp(1.8rem, 10vw, 6.6rem);

  @media (min-width: 768px) {
    font-size: clamp(3.8rem, 8vw, 6rem);
    line-height: clamp(3.8rem, 8vw, 6rem);
  }

  @media (min-width: 1024px) {
    font-size: clamp(4.8rem, 10vw, 7.6rem);
    line-height: clamp(4.8rem, 10vw, 7.6rem);
  }
`;

const Subtitle = styled.p`
  font-family: "Manrope", sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: clamp(1.8rem, 4vw, 3.6rem);
  line-height: clamp(1.8rem, 4vw, 3.6rem);
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  max-width: 80%;
  margin-bottom: clamp(2rem, 8vw, 8rem);

  @media (min-width: 768px) {
    gap: 1rem;
    max-width: 60rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-top: 3rem;

  @media (min-width: 768px) {
    justify-content: flex-start;
    gap: 2rem;
  }
`;

const Hero = () => {
  return (
    <HeroWrapper id="hero">
      <BackgroundLayer />
      <FilterLayer />
      <ContentContainer>
        <TextContent>
          <Title>Efficient Warehousing</Title>
          <Title>Tailored Shipping</Title>
          <ButtonWrapper>
            <Button
              size="xl"
              iconName="FaArrowDown"
              iconSize={1.5}
              scrollTo="services"
            >
              Learn More
            </Button>
          </ButtonWrapper>
        </TextContent>
      </ContentContainer>
    </HeroWrapper>
  );
};

export default Hero;
