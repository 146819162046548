import styled from "@emotion/styled";
import scrollToHash from "utils/scrollToHash";

const StyledNavbar = styled.nav`
  display: flex;
  align-items: center;
  gap: 2rem;

  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #b2b2b2;
  }
`;

const Navbar = () => {
  return (
    <StyledNavbar>
      <StyledLink
        href="#hero"
        onClick={(e) => {
          e.preventDefault();
          scrollToHash("hero");
        }}
      >
        Home
      </StyledLink>
      <StyledLink
        href="#services"
        onClick={(e) => {
          e.preventDefault();
          scrollToHash("services");
        }}
      >
        Services
      </StyledLink>
      <StyledLink
        href="#company"
        onClick={(e) => {
          e.preventDefault();
          scrollToHash("company");
        }}
      >
        Company
      </StyledLink>
      <StyledLink
        href="#about"
        onClick={(e) => {
          e.preventDefault();
          scrollToHash("about");
        }}
      >
        About
      </StyledLink>
    </StyledNavbar>
  );
};

export default Navbar;
