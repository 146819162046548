import styled from "@emotion/styled";
import Navbar from "./Navbar";
import Logo from "./Logo";

const StyledHeader = styled.header`
  background-color: #000000;
  color: #fff;
  padding: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 8rem;

  z-index: 9;

  @media (min-width: 768px) {
    justify-content: space-between;
  }
`;

const StyledNav = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Header = () => {
  return (
    <StyledHeader>
      <Logo />
      <StyledNav>
        <Navbar />
      </StyledNav>
    </StyledHeader>
  );
};

export default Header;
