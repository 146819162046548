import React from "react";
import styled from "@emotion/styled";

const AboutSection = styled.section`
  background-color: #fff;
  color: #000;
  padding: 6rem 2rem;
  width: 100%;

  @media (min-width: 768px) {
    padding: 6rem 4rem;
  }

  @media (min-width: 1024px) {
    padding: 8rem 6rem;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

const AboutHeading = styled.h2`
  font-family: "Play", sans-serif;
  font-size: clamp(3rem, 5vw, 5rem);
  margin-bottom: 2rem;
  max-width: 1200px;
`;

const AboutContent = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: clamp(1.8rem, 2.5vw, 2.2rem);
  line-height: 1.6;
  max-width: 1200px;
`;

const About = () => {
  return (
    <AboutSection id="about">
      <AboutHeading>About Us</AboutHeading>
      <AboutContent>
        DockLine Solutions was founded with a clear goal, to transform how
        businesses handle their supply chain needs. Drawing on decades of
        combined experience in logistics and manufacturing, our team has built a
        company that prioritizes flexibility, efficiency, and quality. We take
        pride in our ability to adapt to the specific needs of our clients,
        whether it’s crafting custom crates for high-value shipments or managing
        complex inventory systems. DockLine Solutions operates out of a
        purpose-built facility designed to support large-scale warehousing and
        light manufacturing. Our investment in cutting-edge equipment and
        technology allows us to deliver reliable, scalable services to
        businesses in industries ranging from e-commerce to manufacturing. At
        DockLine Solutions, we’re more than a logistics company—we’re your
        partner in growth.
      </AboutContent>
    </AboutSection>
  );
};

export default About;
