import React from "react";
import styled from "@emotion/styled";

const OverviewSection = styled.section`
  background-color: #919799;
  color: white;
  padding: 6rem 2rem;
  width: 100%;

  @media (min-width: 768px) {
    padding: 6rem 4rem;
  }

  @media (min-width: 1024px) {
    padding: 8rem 6rem;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

const OverviewHeading = styled.h2`
  font-family: "Play", sans-serif;
  font-size: clamp(3rem, 5vw, 5rem);
  margin-bottom: 2rem;
  max-width: 1200px;
`;

const OverviewContent = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: clamp(1.8rem, 2.5vw, 2.2rem);
  line-height: 1.6;
  max-width: 1200px;
`;

const Overview = () => {
  return (
    <OverviewSection id="company">
      <OverviewHeading>Company Overview</OverviewHeading>
      <OverviewContent>
        DockLine Solutions is a trusted provider of warehousing, logistics, and
        manufacturing services, dedicated to With a state-of-the-art facility
        spanning over 300,000 square feet, we offer scalable storage solutions,
        custom packaging production, and cutting-edge logistics technology. Our
        team is committed to providing tailored services that meet the unique
        demands of our clients, ensuring that every shipment arrives on time and
        in perfect condition. DockLine Solutions is strategically positioned to
        serve regional and national markets with speed and efficiency.
      </OverviewContent>
    </OverviewSection>
  );
};

export default Overview;
