import styled from "@emotion/styled";

const StyledLogo = styled.h1`
  font-size: 3rem;
  font-family: "Play", sans-serif;
`;
const Logo = () => {
  return <StyledLogo>DockLine Solutions</StyledLogo>;
};

export default Logo;
