import React from "react";
import styled from "@emotion/styled";

import Header from "components/Header";
import Hero from "components/Hero";
import Services from "components/Services";
import Overview from "components/Overview";
import About from "components/About";
import Footer from "components/Footer";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const App = () => {
  return (
    <AppContainer>
      <Header />
      <Hero />
      <Services />
      <Overview />
      <About />
      <Footer />
    </AppContainer>
  );
};

export default App;
