import React from "react";
import styled from "@emotion/styled";
import scrollToHash from "utils/scrollToHash";
import Icon from "./Icon";

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: ${({ size }) => {
    switch (size) {
      case "sm":
        return "0.5rem 1rem";
      case "md":
        return "0.75rem 1.5rem";
      case "lg":
        return "1rem 2rem";
      case "xl":
        return "1.25rem 2.5rem";
      default:
        return "0.75rem 1.5rem"; // Default to "md" size
    }
  }};
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-family: "Play", sans-serif;
  font-size: ${({ size }) => {
    switch (size) {
      case "sm":
        return "1rem";
      case "md":
        return "1.2rem";
      case "lg":
        return "1.4rem";
      case "xl":
        return "1.6rem";
      default:
        return "1.2rem";
    }
  }};
  text-transform: uppercase;
  letter-spacing: 0.05em;
  cursor: pointer;
  transition: all 0.3s ease;

  @media (hover: hover) {
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonComponent = ({
  iconName,
  iconSize = 24,
  size = "md",
  children,
  scrollTo,
}) => {
  const handleClick = () => {
    if (scrollTo) {
      scrollToHash(scrollTo);
    }
  };

  return (
    <StyledButton size={size} onClick={handleClick}>
      {children}
      {iconName && (
        <IconWrapper>
          <Icon name={iconName} size={iconSize} />
        </IconWrapper>
      )}
    </StyledButton>
  );
};

export default ButtonComponent;
